<template>
  <div id="page-ladoks">
    <h5 class="m-4">LADOK</h5>

    <b-card>
      <v-tabs background-color="white" color="accent-4" left>
        <v-tab>LADOK Import</v-tab>
        <v-tab>{{$t('LADOK.SEARCH')}}</v-tab>
        <v-tab>{{$t('COMMON.SETTINGS')}}</v-tab>

        <v-tab-item>
          <LADOKJobImport class="mt-8" />
        </v-tab-item>

        <v-tab-item>
          <LADOKSearchTable class="mt-8" />
        </v-tab-item>

        <v-tab-item>
          <EducationSettings 
            class="mt-8"
            :settings="settings" 
          />
        </v-tab-item>
      </v-tabs>
    </b-card>

  </div>
</template>


<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import LADOKJobImport from '@/view/pages/ml/ladok/LADOKJobImport.vue';
import LADOKSearchTable from '@/view/pages/ml/ladok/LADOKSearchTable.vue';
import EducationSettings from '@/view/pages/ml/settings/EducationSettings.vue';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';


export default {
  name: 'LADOK',
  mixins: [ toasts ],
  components: {
    LADOKJobImport,
    LADOKSearchTable,
    EducationSettings
  },
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'periods', 'settings'])
  },
  mounted() {
    
  },
  data() {
    return {
      
    };
  },
  watch: {
    currentCompanyId(newValue, oldvalue) {
      if (newValue) {
        
      }
    }
  },
  methods: {
    
  }
};
</script>
