<template>
  <b-modal size="lg" ref="job-edit-modal" hide-footer>
    <div v-if="item">
      <p>{{ $t('JOB.LADOK_RUN') }} {{item.id}}</p>
      <p><strong>{{ $t('JOB.STATUS') }}: </strong>{{ get_job_status_str(item.status) }}</p>
      <p><strong>{{ $t('JOB.ERROR_MESSAGE') }}: </strong>{{ item.error }}</p>
      <p><strong>{{ $t('JOB.RESULT') }}: </strong>{{ get_job_result_str(item.result) }}</p>
      <p><strong>{{ $t('JOB.NUM_TOTAL') }}:</strong> {{ item.num_records }}</p>
      <p><strong>{{ $t('JOB.NUM_PROCESSED') }}:</strong> {{ item.num_processed }}</p>
      <p><strong>{{ $t('JOB.NUM_OK') }}:</strong> {{ item.num_success }}</p>
      <p><strong>{{ $t('JOB.NUM_FAIL') }}:</strong> {{ item.num_fail }}</p>
      <p><b-button v-if="item.status === 'RUNNING'" variant="danger" @click.prevent="stop_job">{{ $t('JOB.STOP') }}</b-button></p>
    
      <p>LADOK import av medlemmar</p>
      <p><b-button variant="primary" @click.prevent="download_ladok_file">Ladda ned LADOK fil</b-button></p>
      <p v-if="item.options && item.options.error_file_id"><b-button variant="primary-outline" @click.prevent="download_ladok_error_file">Ladda ned Felrapport</b-button></p>

      <RightModalSaveAndCloseButtons
        :text="$t('COMMON.SAVE')"
        :spin="true"
        @clicked="on_save_clicked"
        @close="hide()"
        ref="saveButton" 
      />
    </div>
  </b-modal>
</template>

<script>

// axios is REST API
import axios from 'axios';

// popup messages in the corner
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

// vuex state, storage
import { mapGetters } from 'vuex';

import RightModalSaveAndCloseButtons from '@/view/components/buttons/RightModalSaveAndCloseButtons.vue';
import { get_base_url, downloadWithAxios } from '@/core/services/fileDownload';


export default {
  name: 'LADOKJobEditorModal',
  mixins: [ toasts ],
  props: ['item'],
  emits: ['created', 'updated'],
  components: {
    RightModalSaveAndCloseButtons
  },
  computed: {
    // which stored data we want to use
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'settings', 'periods'])
  },
  mounted() {

  },
  data() {
    return {

    };
  },
  watch: {
    currentCompanyId() {
      // triggers when user has changed company on top
      // we should reload content based on selected company
    },
    currentPeriodId() {
      // triggers when user has changed period on top
      // we should reload content based on period
    }

  },
  methods: {
    async stop_job() {
      try {
        this.item.status = 'TEMP';

        const res = await axios.put(`/job/stop/${this.item.id}`, {});

        if (res.status !== 200) {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('JOB.UNABLE_STOP'));
          return;
        }

        this.toastr('success', this.$t('COMMON.OK'), this.$t('JOB.STOPPED'));
      }
      catch (err) {
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('JOB.UNABLE_STOP'));
      }
    },
    
    async download_ladok_error_file() {

      const res = await axios.get(`/file/${this.item.options.error_file_id}`);
      downloadWithAxios(get_base_url() + `/dlfile/${res.data.linkstr}`, res.data.name);
    
    },

    async download_ladok_file() {
      const res = await axios.get(`/file/${this.item.options.file_id}`)
      downloadWithAxios(get_base_url() + `/dlfile/${res.data.linkstr}`, res.data.name);
      
    },

    get_job_status_str(status) {
      switch (status) {
        case 'DRAFT': return 'Utkast';
        case 'START': return 'Väntar på start';
        case 'RUNNING': return 'Igång';
        case 'FINISHED': return 'Klar';
        case 'DELETED': return 'Raderat';
        case 'CANCELLED': return 'Avbruten';
        case 'CANCEL_REQUESTED': return 'Stoppar...';
      }

      return 'Okänt';
    },

    get_job_result_str(result) {
      switch (result) {
        case 'PENDING': return 'Väntar';
        case 'SUCCESS': return 'Lyckat';
        case 'FAILED': return 'Misslyckat';
        case 'SKIPPED': return 'Hoppa över';
        case 'ERROR': return this.$t('COMMON.ERROR');
        case 'CANCELLED': return 'Avbruten';
        case 'CANCEL_REQUESTED': return 'Stoppar...';
      }

      return 'Okänt';
    },

    on_save_clicked() {
      this.hide();
    },

    created(item) {
      this.$emit('created', item);
    },

    updated(item) {
      this.$emit('updated', item);
    },

    // add any methods we need
    show() {
      this.$refs['job-edit-modal'].show();
    },

    hide() {
      this.$refs['job-edit-modal'].hide();
    }
  }
};
</script>
